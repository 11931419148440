<template>
  <div class="container">
    <div class="contr-content">
      <div class="contr-avatar">
        <span class="text-left no_touch">佩戴状态<br/>{{ currentDevice.Wear ? '已佩戴' : '未佩戴' }}</span>
        <img class="avatar no_touch" :src=getAvatar @click="goEditUserInfo">
        <img class="status no_touch" :src=getStatus>
        <span class="text-right no_touch">电池电量<br/>{{ currentDevice.Battery }}%</span>
      </div>
      <div class="user-name no_touch">{{ user.Item.Username }}</div>
      <!--  查看信息  -->
      <home-card :value="address"
                 :icon="require('../assets/ic_location.png')"
                 @click.native="goLocation"/>
      <home-card :value="step"
                 :icon="require('../assets/ic_walk.png')"
                 @click.native="goStep"/>
      <home-card :value="heartRate"
                 :icon="require('../assets/ic_heart_rate.png')"
                 @click.native="goHeartRate"/>
      <home-card :value="bloodPress" v-if="showBloodPress"
                 :icon="require('../assets/ic_blood.png')"
                 @click.native="goBloodPress"/>
      <home-card :value="bloodOxygen"
                 :icon="require('../assets/ic_blood_oxygen.png')"
                 @click.native="goBloodOxygen"/>
      <home-card :value="temperature" v-if="!hideTemperature"
                 :icon="require('../assets/ic_temperature.png')"
                 @click.native="goTemperature"/>
      <home-card :value="sleep"
                 :icon="require('../assets/ic_sleep.png')"
                 @click.native="goSleep"/>
      <home-card :value="cellphone"
                 :icon="require('../assets/ic_call.png')"
                 @click.native="onCall"/>
      <home-card :value="spoor"
                 :icon="require('../assets/ic_footprint.png')"
                 @click.native="goSpoor"/>
      <home-card :value="safeArea"
                 :icon="require('../assets/ic_protect.png')"
                 @click.native="goSafeArea"/>   
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
// @ is an alias to /src
import {APP_ID, Language, MapType, TimeOffset} from "@/constants/contants";
import HomeCard from "@/components/HomeCard";
import {post, postCMCC, postOpen, isHJQ} from "@/router/httpUtils";
import {Toast} from "vant";
import headImage from "@/assets/app_defult_headimage.png"
import {str2Date, utc2localStr} from "@/util/timeUtil";
import imgWatchOnline from "@/assets/watch_online_icon.png"
import imgWatchLbs from "@/assets/watch_lbs_icon.png"
import imgWatchWifi from "@/assets/watch_wifi_icon.png"
import imgWatchOffline from "@/assets/watch_offline_icon.png"

export default {
  name: 'Device',
  data() {
    return {
      user: {
        AccessToken: String,
        Item: {}
      },
      deviceResponse: '',
      currentDevice: {
        Avatar: headImage,
        Battery: 0
      },
      healthResponse: "",
      address: '时间：\n位置：',
      step: '记步：',
      heartRate: '心率：',
      bloodPress: '血压：',
      bloodOxygen: '血氧：',
      temperature: '体温：',
      sleep: '睡眠：',
      cellphone: '号码：',
      spoor: "足迹",
      safeArea: "安全区域",
      groupId: Number,
      timer: null,
      autoJumpType: '',
      showBloodPress: !isHJQ,
      hideTemperature: true,
    }
  },
  components: {
    HomeCard
  },
  mounted() {
    Toast.loading({
      message: '加载中...',
      forbidClick: true
    })

    if (this.checkRouter()) {
      return
    }

    // this.login()
    this.getUserInfoByIMEI()
  },
  destroyed() {
    if (this.timer) clearInterval(this.timer)
  },
  computed: {
    getAvatar() {
      return this.currentDevice.Avatar ? this.currentDevice.Avatar : headImage
    },
    getStatus() {

      const type = this.currentDevice.PositionType

      if (this.currentDevice.Status == 1 || this.currentDevice.Status == 2) {// 在线
        if (type == 1) {
          return imgWatchOnline;
        } else if (type == 2) {
          return imgWatchLbs;
        } else if (type == 3) {
          return imgWatchWifi;
        }
      }
      // 离线
      return imgWatchOffline

    },
  },
  methods: {
    checkRouter() {
      var r = this.$route.query.router
      console.log(r)
      switch (r) {
        case 'bindIMEI': 
          this.$router.push({name: 'BindIMEI'})
        break
        case 'unbindIMEI': 
          this.$router.push({name: 'UnBindIMEI'})
        break
        case 'safeAreaBrowser': 
          this.$router.push({name: 'SafeAreaBrowser'})
        break
        case 'location':
          this.autoJumpType = r
          return false
        default :
        return false
      }
      return true
    },
    onCall() {
      if (this.currentDevice.Sim) {
        window.open('tel:' + this.currentDevice.Sim, '_blank')
      }
    },
    goEditUserInfo() {
      this.$router.push({name: 'EditUserInfo'})
    },
    goLocation() {
      this.$router.push({name: 'Location'})
    },
    goStep() {
      this.$router.push({name: 'Step'})
    },
    goHeartRate() {
      this.$router.push({name: 'HeartRate'})
    },
    goBloodPress() {
      this.$router.push({name: 'BloodPress'})
    },
    goBloodOxygen() {
      this.$router.push({name: 'BloodOxygen'})
    },
    goTemperature() {
      this.$router.push({name: 'Temperature'})
    },
    goSleep() {
      this.$router.push({name: 'Sleep'})
    },
    goSpoor() {
      this.$router.push({name: 'Spoor'})
    },
    goSafeArea() {
      this.$router.push({name: 'SafeAreaList',})
    },
    getUserInfoByIMEI() {
      const imei = this.$route.query.deviceId ? this.$route.query.deviceId : this.$route.query.deviceid ? this.$route.query.deviceid : localStorage.imei
      postCMCC(isHJQ ? '/cmccopen/GetDeviceByIMEI' : 'Ketronopen/GetDeviceByIMEI', {
        imei: imei
      }).then(res => {
        console.log(res)
        if (res.state == -1) {
          Toast({
            message: res.msg,
            duration: 3000
          })
          return
        }
        localStorage.imei = imei
        localStorage.DeviceId = res.deviceId
        this.groupId = res.groupId
        this.login(res.userName)
      })
      // .catch(err => {
        // Toast.fail({
        //   message: err
        // })
      // })
    },
    login(userName) {

      post('/api/User/Login', {
        "AppId": APP_ID,
        "Language": Language,
        // "Name": "15538307252",
        // "Name": "13105520666",
        "Name": userName,
        "Pass": "123456"
      })
          .then(res => {
            console.log(res);
            this.user = res
            localStorage.Token = res.AccessToken
            localStorage.UserId = res.Item.UserId
            if (userName) {
              this.getPersonDeviceList()
            } else {
              this.getUserGroupList()
            }
          })
          .catch(err => {
            console.log(err);
          })
    },
    getUserGroupList() {
      post('/api/Group/UesrGroupList', {
        "Language": Language,
        "Token": this.user.AccessToken,
        "UserId": this.user.Item.UserId
      })
          .then(response => {
            // handle success
            console.log(response);
            this.groupId = response.Items[0].groupId
            this.getPersonDeviceList()
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
    },
    getPersonDeviceList() {
      post('/api/Device/PersonDeviceList', {
        "GroupId": this.groupId,
        "LastTime": "",
        "MapType": MapType,
        "Token": this.user.AccessToken,
        "UserId": this.user.Item.UserId
      })
          .then(response => {
            // handle success
            console.log(response);
            this.deviceResponse = response
            this.currentDevice = this.deviceResponse.Items.find(item => {
              return item.Id == localStorage.DeviceId
            })
            // this.currentDevice = this.deviceResponse.Items.find((d) => d.deviceId)
            const phone = this.currentDevice.Sim ? this.currentDevice.Sim : "未设置号码"
            this.cellphone = "号码：" + phone
            this.hideTemperature = this.currentDevice.Type == "H003";
            localStorage.DeviceId = this.currentDevice.Id
            localStorage.TypeValue = this.currentDevice.TypeValue
            localStorage.SerialNumber = this.currentDevice.SerialNumber
            localStorage.lat = this.currentDevice.OLat
            localStorage.lng = this.currentDevice.OLng

            if (this.autoJumpType) {
              this.autoJump()
              return 
            }

            this.getAddress()
            this.getHealthInfo()
            this.getTemperature()

            this.timer = setInterval(() => {
              this.getAddress()
              this.getHealthInfo()
              this.getTemperature()
            }, 10 * 1000)
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(() => {
            Toast.clear()
          })
    },
    getHealthInfo() {
      post('/api/Device/HealthInfo', {
        "AppId": APP_ID,
        "Language": Language,
        "DeviceId": this.currentDevice.Id,
        "TimeOffset": TimeOffset,
        "Token": this.user.AccessToken
      })
          .then(response => {
            console.log(response);
            this.healthResponse = response
            this.heartRate = "心率：" + this.healthResponse.HeartRate + " 时间：" + this.healthResponse.LastUpdateTime
            this.bloodPress = "血压：" + this.healthResponse.BloodMax + "/" + this.healthResponse.BloodMin
            this.bloodOxygen = "血氧：" + this.healthResponse.BloodOxygen + " 时间：" + this.healthResponse.LastUpdateTime
            
            this.step = "计步：" + this.healthResponse.Step + "步 " + this.healthResponse.Distance + "公里 " + this.healthResponse.Energy.toFixed(2) + "卡路里"
            this.sleep = "睡眠：" + this.healthResponse.SleepAll.toFixed(1) + "时 深睡：" + this.healthResponse.DeepSleep.toFixed(1) + "时 浅睡：" + this.healthResponse.LightSleep.toFixed(1) + "时"
          })
          .catch(function (error) {
            console.log(error);
          })
    },
    getTemperature() {
      postOpen('/Open/GetTiWenPo', {
        "IMEI": localStorage.imei,
        "TimeOffset": TimeOffset,
      })
        .then(response => {
          console.log(response);
          var resp = JSON.parse(response.content)
          this.temperature = "体温：" + resp.TiWen + " 时间：" + resp.DeviceUtcTime.replace('T', ' ')
        })
        .catch(function (error) {
            console.log(error);
        })
    },
    getAddress() {
      post('/api/Location/Address', {
        "Language": Language,
        "Lat": this.currentDevice.OLat,
        "Lng": this.currentDevice.OLng,
        "Token": this.user.AccessToken
      })
          .then(response => {
            // handle success
            console.log(response);

            this.address = "时间：" + utc2localStr(str2Date(this.currentDevice.DeviceUtcTime)) + "\n位置：" + response.Address
            localStorage.Address = response.Address
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
    },
    autoJump() {
      switch(this.autoJumpType) {
        case 'location':
          this.goLocation()
          break
      }
    },
    initHJQ() {
      /* eslint-disable */
      const Hejia = window.Hejia
      console.log('Home start Hejia')
      Hejia.ready(function () {
        // 页面加载完成后要立即调用Hejia全局对象执行的代码逻辑写这里
        console.log(' Home Hejia ready')

        Hejia.getDeviceId(function (version) {
          // IMEI - string => deviceId
          console.log('getDeviceId')
          console.log(version)
          // alert(version);

          Hejia.getDeviceInfo({deviceId: version}, function (obj) {
            // obj - Object => {device：{...}, location: {...}}.
            console.log('getDeviceInfo')
            console.log(obj.device.id);
          }, function (msg, obj) {
            // msg - String => error message
            // obj – Object => { resultCode:!0, resultCodeMessage: string }
            console.log('getDeviceInfo faild')
            console.log(msg);
            console.log(obj.resultCode + obj.resultCodeMessage);
          });
        }, function (err) {
          console.log('getDeviceId failed')
          console.log(err);
          //do something
        });

        Hejia.getToken(function (token) {
          // token - string => token
          console.log('getToken');
          console.log(token);
        }, function (err) {
          console.log('getToken failed')
          console.log(err);
          //do something
        });

        Hejia.getPhoneNumber(function (version) {
          // mobile - string => mobile phone
          console.log('getPhoneNumber');
          console.log(version);
        }, function (err) {
          console.log('getPhoneNumber failed')
          console.log(err);
          //do something
        });

        Hejia.getApiKey(function (apiKey) {
          console.log('apiKey');
          console.log(apiKey);
        }, function (err) {
          console.log('getApiKey failed')
          console.log(err);
          //do something
        });

        Hejia.getCurrentParam(function (device) {
          // device - Object => device object.
          console.log('getCurrentParam');
          console.log(device);
        }, function (msg, obj) {
          // msg - String => error message
          // obj – Object => { resultCode:!0, resultCodeMessage: string }
          console.log('getCurrentParam failed');
          console.log(msg);
          console.log(obj.resultCode + obj.resultCodeMessage);
        });

      });
      /* eslint-enable */
    }
  }
}
</script>

<style scoped>
.container {
  background-color: #f6f8f9;
  overflow: hidden;
}

.contr-content {
  overflow: auto;
  height: 100%;

  /*下面三行让图片居中*/
  vertical-align: middle;
  text-align: center;
}

.text-left {
  position: absolute;
  text-align: center;
  top: 40%;
  left: 30px;
  color: #11B7F3;
  line-height: 20px;
}

.text-right {
  position: absolute;
  text-align: center;
  top: 40%;
  right: 30px;
  color: #11B7F3;
  line-height: 20px;
}

.contr-avatar {
  margin-top: 40px;
  position: relative;
}

.avatar {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

.status {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 0;
  right: calc(50vw - 60px);
}

.user-name {
  margin-top: 20px;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style> 