import axios from "axios";
// import AppToken from "@/constants/contants";

export const isHJQ = false;

const request = axios.create({
    baseURL: "https://api.xiaoxinhuan.com",
    timeout: 20000   
})

const requestCMCC = axios.create({
    baseURL: isHJQ ? "https://10086.xiaoxinhuan.com" : "https://ketron.xiaoxinhuan.com",
    timeout: 20000,
    // withCredentials: true,
})

const requestOpen = axios.create({
    baseURL: "https://openapi.xiaoxinhuan.com",
    timeout: 20000
})

export function post(url, params = {}, headers) {
    return new Promise((success, failed) => {
        request.post(url, getURLParams(params), headers)
            .then(res => {
                success(res.data)
            })
            .catch(err => {
                failed(err)
            })
    })
}

export function postNormal(url, params = {}, headers) {
    return new Promise((success, failed) => {
        request.post(url, params, {
            'headers': {
                ...headers,
            }
        })
            .then(res => {
                success(res.data)
            })
            .catch(err => {
                failed(err)
            })
    })
}

export function postCMCC(url, params = {}, headers) {
    return new Promise((success, failed) => {
        requestCMCC.post(url, getURLParams(params), {
            'headers': {
                ...headers,
            }
        })
            .then(res => {
                success(res.data)
            })
            .catch(err => {
                failed(err)
            })
    })
}

export function postCMCCNormal(url, params = {}, headers) {
    return new Promise((success, failed) => {
        requestCMCC.post(url, params, {
            'headers': {
                ...headers,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Access-Control-Allow-Methods': 'PUT,POST,GET,DELETE,OPTIONS',
                'Access-Control-Allow-Credentials': 'true',
            },
        })
            .then(res => {
                success(res.data)
            })
            .catch(err => {
                failed(err)
            })
    })
}

export function getCMCC(url, p = {}) {
    return new Promise((success, failed) => {
        requestCMCC.get(url, {
            params: p
          })
            .then(res => {
                success(res.data)
            })
            .catch(err => {
                failed(err)
            })
    })
}

export function postOpen(url, params = {}, headers) {
    return new Promise((success, failed) => {
        requestOpen.post(url, getURLParams(params), {
            'headers': {
                ...headers,
                "AppToken": 'bd481f06d2e19accab48965ada3b8f14d66d02b78b345d67908e4dc8f9d8860aefe0ad391d8963586632c4cba89bf64a',
            }
        })
            .then(res => {
                success(res.data)
            })
            .catch(err => {
                failed(err)
            })
    })
}

export function getURLParams(params = {}) {
    // let data = new URLSearchParams()
    // for(let key in params) {
    //     data.append(key, params[key])
    // }

    let getPairs = (obj, keys = []) =>
        Object.entries(obj).reduce((pairs, [key, value]) => {
            if (value == null) value = "";
            if (typeof value === 'object')
                pairs.push(...getPairs(value, [...keys, key]));
            else 
               pairs.push([[...keys, key], value]);
            return pairs;
        }, []);

    const param = getPairs(params)
        .map(([[key0, ...keysRest], value]) =>
            `${key0}${keysRest.map(a => `[${a}]`).join('')}=${value}`)
        .join('&')
    // console.log(param)
    return param
}
